var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-producer" }, [
    _c("header", { staticClass: "header header--borderBottom" }, [
      _c("div", { staticClass: "container headerContainer" }, [
        _vm._m(0),
        _c("div", { staticClass: "headerContainer__alignRight" }, [
          _vm.hasEliteSubscription
            ? _c(
                "a",
                {
                  staticClass: "btn btn--danger btn--sm",
                  attrs: { href: _vm.productPageLink, target: "_blank" },
                },
                [_vm._v("CUSTOMIZE DESIGN")]
              )
            : _vm._e(),
        ]),
      ]),
    ]),
    _c(
      "section",
      { staticClass: "section" },
      [
        _vm.isLoading
          ? _c(
              "div",
              { staticClass: "container container--fullWidth" },
              [_c("VLoadSpinner")],
              1
            )
          : !_vm.isLoading && !_vm.hasEliteSubscription
          ? _c("AlertBoxSubscription")
          : [
              _c("div", { staticClass: "container container--fullWidth" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "container container--fullWidth grid grid__col-one-third",
                  },
                  [
                    _c("div", { staticClass: "flexbox flexbox--center" }, [
                      _c("div", [
                        _c("div", { staticClass: "formGroup" }, [
                          _c(
                            "h4",
                            { staticClass: "form__title h-textCenter" },
                            [_vm._v("Product Image")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "formUpload formUpload--square",
                              class: {
                                "formUpload--empty": !_vm.product.image_url,
                                "formUpload--uploaded": _vm.product.image_url,
                              },
                            },
                            [
                              _c("icon", {
                                staticClass: "formUpload__icon",
                                attrs: { name: "camera" },
                              }),
                              _vm.product.image_url
                                ? _c("img", {
                                    staticClass: "formUpload__image",
                                    attrs: { src: _vm.product.image_url },
                                  })
                                : _vm._e(),
                              _c("input", {
                                staticClass: "formUpload__inputHide",
                                attrs: { type: "file" },
                                on: {
                                  change: function ($event) {
                                    return _vm.uploadFile(
                                      $event.target.files,
                                      _vm.FileToRemoveOrAdd.Image
                                    )
                                  },
                                },
                              }),
                              _vm.product.image_url
                                ? _c(
                                    "a",
                                    {
                                      staticClass:
                                        "btn btn--danger btn--sm formUpload__btnDelete",
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeFile(
                                            _vm.FileToRemoveOrAdd.Image
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Remove")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                    _c("ValidationObserver", [
                      _c(
                        "div",
                        { staticClass: "wrapper--filled wrapper--paddingLg" },
                        [
                          _c(
                            "div",
                            { staticClass: "formGroup formGroup--horizontal" },
                            [
                              _c(
                                "div",
                                { staticClass: "flex75" },
                                [
                                  _c("label", { staticClass: "formLabel" }, [
                                    _vm._v("Name"),
                                  ]),
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "Name",
                                      rules: { required: true },
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.product.name,
                                                  expression: "product.name",
                                                },
                                              ],
                                              staticClass:
                                                "formInput formInput--fullWidth",
                                              class: {
                                                hasError: errors.length > 0,
                                              },
                                              attrs: {
                                                name: "name",
                                                type: "text",
                                                placeholder:
                                                  "Enter service name",
                                              },
                                              domProps: {
                                                value: _vm.product.name,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.product,
                                                    "name",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "formInput__hint formInput__hint--error",
                                              },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "flex25" },
                                [
                                  _c("label", { staticClass: "formLabel" }, [
                                    _vm._v("Price"),
                                  ]),
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "Price",
                                      rules: { required: true, numeric: true },
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model.number",
                                                  value: _vm.product.price,
                                                  expression: "product.price",
                                                  modifiers: { number: true },
                                                },
                                              ],
                                              staticClass:
                                                "formInput formInput--fullWidth",
                                              class: {
                                                hasError: errors.length > 0,
                                              },
                                              attrs: {
                                                name: "price",
                                                type: "text",
                                                placeholder: "20",
                                              },
                                              domProps: {
                                                value: _vm.product.price,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.product,
                                                    "price",
                                                    _vm._n($event.target.value)
                                                  )
                                                },
                                                blur: function ($event) {
                                                  return _vm.$forceUpdate()
                                                },
                                              },
                                            }),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "formInput__hint formInput__hint--error",
                                              },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c("hr", { staticClass: "form__divider" }),
                          _c("div", { staticClass: "formGroup" }, [
                            _c("h4", { staticClass: "form__title h-mb-10" }, [
                              _vm._v("Upload Product File (ZIP or RAR)"),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "formUpload formUpload--sm formUpload--empty",
                              },
                              [
                                _c("icon", {
                                  staticClass: "formUpload__icon",
                                  class: {
                                    "formUpload__icon--primary":
                                      _vm.product.download_file_name,
                                  },
                                  attrs: { name: "zip" },
                                }),
                                _vm.product && _vm.product.download_file_name
                                  ? _c("h4", [
                                      _vm._v(
                                        _vm._s(_vm.productFileNameCleaned)
                                      ),
                                    ])
                                  : _vm._e(),
                                _c(
                                  "label",
                                  { staticClass: "formUpload__labelDropzone" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        !_vm.product.download_file_name
                                          ? "Drop file here"
                                          : "Re-upload file here"
                                      )
                                    ),
                                  ]
                                ),
                                _c("input", {
                                  staticClass: "formUpload__inputHide",
                                  attrs: { type: "file" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.uploadFile(
                                        $event.target.files,
                                        _vm.FileToRemoveOrAdd.ProductFile
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ],
      ],
      2
    ),
    !_vm.isLoading && _vm.hasEliteSubscription
      ? _c("section", { staticClass: "section__bottom-button" }, [
          _c(
            "a",
            {
              staticClass: "btn btn--primary",
              on: { click: _vm.saveAllChanges },
            },
            [_vm._v("SAVE CHANGES")]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "headerContainer__heading" }, [
      _c("h1", [_vm._v("Product Offer ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }